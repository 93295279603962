import { useEffect, useMemo, useState } from 'react';

import generateTelegramLink from 'src/utils/generateTelegramLink';
import generateWhatsappLink from 'src/utils/generateWhatsappLink';
import getDeviceType from './utils/getDeviceType';

import logo from 'src/assets/images/Logo@2x.png';
import bannerBg from 'src/assets/images/Path 19310@2x.png';
import bannerImg from 'src/assets/images/vector@2x.png';
import desktopImg from 'src/assets/images/Mobile browsers-cuate@2x.png';
import whatsappIcon from 'src/assets/icons/whatsapp.svg';
import telegramIcon from 'src/assets/icons/telegram.svg';
import facebookIcon from 'src/assets/icons/facebook.svg';
import instaIcon from 'src/assets/icons/insta.svg';
import { MetaPixel } from './components';

function App() {
  const [layoutData, setLayoutData] = useState({});
  const socialMedia = useMemo(
    () =>
      [
        {
          icon: whatsappIcon,
          link: generateWhatsappLink({ phone_number: layoutData?.social_media?.whatsapp, message: 'انا مستخدم من موقع سيزما، اريد الاستفسار عن شئ', link: window.location.href || '' }),
          data: layoutData?.social_media?.whatsapp,
        },
        {
          icon: telegramIcon,
          link: generateTelegramLink(layoutData?.social_media?.telegram),
          data: layoutData?.social_media?.telegram,
        },
        {
          icon: facebookIcon,
          link: layoutData?.social_media?.facebook,
          data: layoutData?.social_media?.facebook,
        },
        {
          icon: instaIcon,
          link: layoutData?.social_media?.instagram,
          data: layoutData?.social_media?.instagram,
        },
      ].filter((item) => item?.data),
    [layoutData?.social_media]
  );
  const getLayoutData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/layout`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: 'application/json',
          Platform: 'web',
          'Accept-Language': 'ar',
        },
      });
      const data = await response.json();
      setLayoutData(data?.data);
    } catch (err) {
      console.error('err', err);
    }
  };

  const handleClickDownloadBtn = () => {
    window.fbq('track', 'InitiateCheckout', {
      content_name: 'App Download',
      content_type: 'button_click',
      value: layoutData?.platforms?.android_link || layoutData?.platforms?.ios_link,
    });
    getDeviceType() == 'android' ? window.open(layoutData?.platforms?.android_link, '_blank') : window.open(layoutData?.platforms?.ios_link, '_blank');
  };
  const handleClickSocialBtn = (item = {}) => {
    window.fbq('track', 'Contact', {
      content_name: 'Social Media Click',
      content_type: 'social_media',
      content_category: item?.data,
    });
  };

  useEffect(() => {
    getLayoutData();
  }, []);

  if (getDeviceType() == 'desktop') {
    return (
      <div className='text-[25px]  font-semibold relative w-full max-w-[1033px] mx-auto min-h-[100vh] flex flex-col justify-center  '>
        <img src={desktopImg} className='mx-auto w-full sm:max-w-[343px] max-w-[190px] mt-[173px]' alt='' />
        <p className='text-center mt-[3vh]'>عذرا .. هذا الموقع متاح عبر الموبايل فقط</p>
      </div>
    );
  }
  return (
    <>
      <MetaPixel />
      <div className=' relative w-full max-w-[1033px]  mx-auto h-[100vh] flex flex-col  '>
        <header className='bg-white '>
          <img src={logo} className='mx-auto w-full sm:max-w-[343px] max-w-[190px] mt-[3vh]' alt='' />
        </header>
        <section className='  bg-transparent relative z-10 flex flex-col justify-end  w-full h-[50vh] sm:h-[631.88px]'>
        <button onClick={handleClickDownloadBtn} className="bg-blue-600 rounded-[31px] w-full max-w-[612px] p-2"  style={{ zIndex: 100, marginBottom: '11px', marginTop: '11px', color: 'white' ,     position: 'relative', alignSelf: 'center'}}><p className="border w-full rounded-[26px] text-[25px] sm:text-[54px] py-2 ">حمل التطبيق مجانا</p></button>
          <img src={bannerBg} className='h-full w-full absolute -z-10' alt='' />
          <img src={bannerImg} className='max-w-[775.09px] w-full mx-auto items-end' alt='' />
          <div className='bg-[#1B1B1B] h-1/2 w-full absolute -z-20 '></div>
        </section>
        <footer className='bg-[#1B1B1B] flex-1 pb-20 pt-10 px-5 relative text-white gap-y-6 flex flex-col items-center text-[25px] sm:text-[79px] font-bold justify-center'>
          <h2>من البائع للمشتري</h2>
          <h2>بدون وسـيــــــــط</h2>
          <div className='flex gap-x-4 mt-[1vh]'>
            {socialMedia.map((item, index) => (
              <a key={index} onClick={() => handleClickSocialBtn(item)} href={item.link} title='Find us' target='_blank' className='cursor-pointer rounded-full transition-all duration-300'>
                <img src={item.icon} alt='logo-ct' className=' sm:w-[76px] sm:h-[76px] w-[37px] h-[37px]' />
              </a>
            ))}
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
